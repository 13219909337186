<template>
  <div class="page container p-4">
    <div class="columns">
      <form
        class="column is-6 is-offset-3"
        @submit.prevent="sendContact"
      >
        <h1 class="has-text-centered has-text-weight-medium is-size-3">
          Contacto
        </h1>

        <anon-contact-info />

        <faq-direct-links />

        <b-field v-if="!initializedFromCurrentUser">
          <b-input
            ref="firstname"
            v-model="form.firstname"
            type="text"
            placeholder="Nombre"
            required
            maxlength="100"
          />
        </b-field>
        <b-field v-if="!initializedFromCurrentUser">
          <b-input
            ref="lastname"
            v-model="form.lastname"
            type="text"
            placeholder="Apellido(s)"
            required
            maxlength="100"
          />
        </b-field>
        <b-field v-if="!initializedFromCurrentUser">
          <b-input
            ref="email"
            v-model="form.email"
            type="email"
            placeholder="Email"
            required
            maxlength="100"
          />
        </b-field>
        <b-field>
          <b-input
            ref="message"
            v-model="form.message"
            type="textarea"
            placeholder="Mensaje"
            required
            maxlength="500"
          />
        </b-field>
        <b-field v-if="!initializedFromCurrentUser">
          <b-checkbox
            ref="privacyPolicy"
            v-model="form.legal_accepted"
            required
          >
            <b-button
              class="px-0"
              type="is-text"
              @click="openPrivacyPolicy"
            >
              Acepto la política de privacidad
            </b-button>
          </b-checkbox>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          expanded
        >
          Enviar
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>

import LegalContent from '@/assets/legal/privacy-policy.json'
import AnonContactInfo from "@/components/contact/AnonContactInfo";
import FaqDirectLinks from "@/components/contact/FaqDirectLinks";

export default {
  title: 'Contacto',
  name: 'About',
  components: {FaqDirectLinks, AnonContactInfo},
  data: function () {
    return {
      isLegalModalOpen: false,
      initializedFromCurrentUser: false,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        legal_accepted: false
      },
      legalText: LegalContent
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.isAuth
    },
    userSchoolId() {
      if (this.isAuth && this.$store.state.user.school_id > 0) {
        return this.$store.state.user.school_id
      }

      return null
    }
  },
  mounted() {
    if (this.isAuth) {
      this.resetForm()
    }

    try {
      this.focusForm()
    } catch (e) {
      console.warn("No se ha podido hacer foco en el formulario")
    }
  },
  methods: {
    initWithCurrentUserData() {
      const userData = this.$store.state.user

      if (!userData) {
        return
      }

      this.form.firstname = userData.firstname
      this.form.lastname = userData.lastname
      this.form.email = userData.email

      this.initializedFromCurrentUser = true
    },
    async sendContact() {
      try {
        await this.$store.dispatch('initLoading')

        const response = await this.$api.contact.send(this.form)

        await this.$store.dispatch('finishLoading')

        this.$msg.success(response.message)

        this.resetForm()
      } catch (error) {
        await this.$store.dispatch('finishLoading')
        const data = error.response.data
        this.$msg.error(data.message)
      }
    },
    resetForm() {
      this.form.firstname = ''
      this.form.lastname = ''
      this.form.email = ''
      this.form.message = ''
      this.form.legal_accepted = false

      if (this.isAuth) {
        this.initWithCurrentUserData()
      }
    },
    openPrivacyPolicy() {
      const currentYear = ''+(new Date()).getFullYear()
      this.$buefy.dialog.alert({
        title: this.legalText.title,
        message: this.legalText.content.split('{currentYear}').join(currentYear),
        confirmText: 'Cerrar'
      })
    },
    focusForm() {
      if (this.isAuth) {
        this.$refs.message.focus()
      }

      if (!this.isAuth) {
        this.$refs.firstname.focus()
      }
    }
  }
}
</script>

<style type="scss" scoped>
</style>
