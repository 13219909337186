<template>
  <div>
    <p>Puede contactar directamente en la escuela o mediante alguno de los siguientes canales:</p>
    <ul class="mb-4">
      <li>Email: <a href="mailto:app@musicaeduca.es">app@musicaeduca.es</a></li>
      <li>Teléfono: <a href="tel:918821956">91 882 19 56</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AnonContactInfo"
}
</script>
