<template>
  <div class="mb-4">
    <b-notification
      class="mb-1 handled"
      type="is-info is-light"
      :closable="false"
      @click="openCodeArticle"
    >
      ¿Problemas con los códigos?, <span class="has-text-weight-bold">pulsa aquí</span>
    </b-notification>
    <b-notification
      class="handled"
      type="is-info is-light"
      :closable="false"
      @click="openRegisterArticle"
    >
      ¿Problemas con el registro?, <span class="has-text-weight-bold">pulsa aquí</span>
    </b-notification>
  </div>
</template>

<script>
export default {
  name: "FaqDirectLinks",
  data(){
    return {
      closedCodesNotif: false,
      closedRegisterNotif: false
    }
  },
  methods: {
    async openCodeArticle(){
      await this.$router.push('/article/4798')
    },
    async openRegisterArticle(){
      await this.$router.push('/article/233')
    }
  }
}
</script>
